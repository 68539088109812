import { Card, CardProps, createPolymorphicComponent } from '@mantine/core';
import { forwardRef, useMemo } from 'react';
import { ContentDecorationCosmetic } from '~/server/selectors/cosmetic.selector';
import { useFrameStyles } from '~/components/Cards/Cards.styles';
import { CosmeticLights } from '~/components/Cards/components/CosmeticLights';
import { TwCosmeticWrapper } from '~/components/TwCosmeticWrapper/TwCosmeticWrapper';
import { TwCard, TwCardAnchor } from '~/components/TwCard/TwCard';

type MasonryCardProps = CardProps & {
  height?: number;
  uniform?: boolean;
  frameDecoration?: ContentDecorationCosmetic | null;
};

// TODO - when children not in view, replace child react nodes with static html
const _MasonryCard = forwardRef<HTMLDivElement, MasonryCardProps>(
  ({ height, children, style, uniform, frameDecoration, className, ...props }, ref) => {
    return (
      <TwCosmeticWrapper cosmetic={frameDecoration?.data}>
        {/* <CosmeticLights frameDecoration={frameDecoration} /> */}
        <TwCard ref={ref as any} style={{ height, ...style }} className={className}>
          {children}
        </TwCard>
      </TwCosmeticWrapper>
    );
  }
);
_MasonryCard.displayName = 'MasonryCard';

export const MasonryCard = createPolymorphicComponent<'div', MasonryCardProps>(_MasonryCard);
